module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Raleway:300,400,500,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stephen Sparling | Fullstack Dev","short_name":"Stephen Sparling","start_url":"/","background_color":"#1A202C","theme_color":"#1A202C","display":"minimal-ui","icon":"src/images/favicon.png"},
    }]
